import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import Box from "@mui/material/Box";

export default function ContactInfoPreview({ contactInfo }) {
  const [textColour, setTextColour] = React.useState("white");

  const iconStyle = {
    color: textColour,
    fontSize: "1.5rem",
    width: "12%",
  }

  const typographyStyle = {
    color: textColour,
    ml: "1em",
    fontWeight: "500",
    fontSize: "0.9rem",
    
  }
  return (
    <div
      width="100%"
      style={{
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      <Accordion
        square
        elevation={0}
        width="100%"
        onMouseOver={() => {
          setTextColour("white");
        }}
        onMouseLeave={() => {
          setTextColour("white");
        }}
        onMouseDown={() => {
          setTextColour("white");
        }}
        sx={{
          // height: "100%",
          width: "100%",
          backgroundColor: "primary.main",
          color: "white",
          // padding: "2px 6px",
          "&:hover": {
            backgroundColor: "primarry.main",
            color: "white",
            transition: "0.2s",
          },
          marginBottom: "10px",
        }}
      >
        <AccordionSummary
          sx={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: 0,
            // padding: "2px 0"
           
            py: 1,
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: textColour,
                // "&:hover": {
                //   color: "white",
                //   transition: "0.2s",
                // },
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h4"
            align="center"
            fontFamily={"Roboto Condensed"}
            sx={{
              fontFamily: "'Roboto Condensed', sans-serif",
              textAlign: "center",
              color: textColour,
              fontWeight: "700",
              fontSize: {
                xs: "1.5rem",
                sm: "1.5rem",
                md: "1.2rem",
              },
             
              width: "100%",
              ml: "1em",
            }}
          >
            {contactInfo.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          overflow: "hidden",
          width: "100%",
        }}>
          <Box
            sx={{
              width: "80%",
              height: "100%",
              
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              // mx: "10%",
            }}
          >
           
              <Box
                sx={{
                  mb: "0.8rem",
                 
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    
                    // mb: "0.1rem",
                  }}
                >
                  <AlternateEmailIcon
                    sx={iconStyle}
                  />

                  
                    <Typography
                      sx={typographyStyle}
                    >
                      {contactInfo.email}
                      {/* rosita.dmello07@gmail.com */}
                    </Typography>
               
                </Box>
              </Box>
              <Box
                sx={{
                  mb: "0.8rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // mb: "0.1rem",
                  }}
                >
                  <LocalPhoneIcon
                    sx={iconStyle}
                  />
                 
                    <Typography
                      sx={typographyStyle}
                    >
                      {contactInfo.phone}
                    </Typography>
               
                </Box>
              </Box>
              <Box
                sx={{
                  mb: "0.8rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // mb: "0.1rem",
                  }}
                >
                  <BusinessIcon
                    sx={iconStyle}
                  />
                   
                  <Typography
                    sx={{...typographyStyle,
                    lineHeight: "1rem",}}
                  >
                    {contactInfo.address}
                  </Typography>
                   
                </Box>
              </Box>
            
          </Box>
          {/* <Grid
            container
            sx={{
              width: "100%",
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                
                }}
              >
                <AlternateEmailIcon sx={{ color: textColour }} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: textColour,
                    ml: "1em",
                    fontWeight: "600",
                  }}
                >
                  {contactInfo.email}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                }}
              >
                <LocalPhoneIcon sx={{ color: textColour }} />
              </Box>
              <Box
                sx={
                  {
                    //   width: "80%",
                  }
                }
              >
                <Typography
                  sx={{
                    color: textColour,
                    ml: "1em",
                    fontWeight: "600",
                  }}
                >
                  {contactInfo.phone}
                </Typography>
              </Box>
            </Grid>
          </Grid> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
