import { Box } from '@mui/material'
import React from 'react'
import Typography from '../modules/components/Typography'
import withRoot from '../modules/withRoot'
function PageNotFound() {
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',

    }}>
        <img src="/Web2.png" alt="logo" height="100"/>
        <Typography variant="h1" sx={{textAlign: 'center', textTransform: 'none', marginBottom: '20px', marginTop: '20px', fontWeight: '700', 
        color: 'secondary.main'
      }}>OOPS!</Typography>
        <Typography variant="h3" sx={{textAlign: 'center', textTransform: 'none', marginBottom: '20px', fontWeight: '600'}}>PAGE NOT FOUND</Typography>
        <Typography variant="h5" sx={{textAlign: 'center', textTransform: 'none', marginBottom: '20px', fontWeight: '400'}}>The page you are looking for does not exist.</Typography>
        
    </Box>
  )
}

export default withRoot(PageNotFound)