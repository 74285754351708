import logo from "./logo.svg";
import "./App.css";
import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/auth/SignIn";
import CreateAccount from "./pages/auth/CreateAccount";
import ViewUsers from "./pages/admin/ViewUsers";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import ManageLinks from "./pages/ManageLinks";
import ViewLinks from "./pages/ViewLinks";
import LoadingPage from "./pages/LoadingPage";

import moment from "moment";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./pages/auth/ResetPassword";
moment().format();

function App() {
  const [loggedIn, setLoggedIn] = React.useState(true);

  const checkLoggedIn = () => {
    const setAt = localStorage.getItem("setAt");
    
    if(setAt && setAt !== "") {
      const expiry = moment(setAt).add(24, 'hours');
      const time = moment();
      if (time.isBefore(expiry)) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        localStorage.setItem("user", "");
        localStorage.setItem("setAt", "");
        localStorage.setItem("token", "")
      }
    } 
  }
  useEffect(() => {
   checkLoggedIn();
  }, []);
  return (
    <Router>
      <div style={{
        margin: 0,
      }}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/sign-in" element={<SignIn/>}/>
          <Route path="/change-password" element={<ChangePassword/>}/>
          <Route path="/forgot-password" element={<ForgotPassword/>}/>
          <Route path="/reset-password/:token" element={<ResetPassword/>}/>
          <Route path="/manage" element={<ManageLinks/>}/>
          <Route path="/view/:userId" element={<ViewLinks/>}/>

          {/* Loading Page */}
          <Route path="/loading" element={<LoadingPage/>}/>

          {/* 404 Page */}
          <Route path="/404" element={<PageNotFound/>}/>
          
          {/* Admin Paths */}
          <Route path="/admin/create-account" element={<CreateAccount/>}/>
          <Route path="/admin/view-users" element={<ViewUsers/>}/>

          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
