/**
 * This is a simplified logic.
 * Consider using `import isEmail from 'validator/lib/isEmail'` from
 * https://github.com/validatorjs/validator.js/blob/7376945b4ce028b65955ae57b8fccbbf3fe58467/src/lib/isEmail.js
 * for a more robust version.
 */
function isEmail(string) {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(string);
}

function isTel(string) {
  const re = /^\d{10}$/;
  return re.test(string);
}

function isPassword(string) {
  const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/;
  return re.test(string);
}

export function password(value) {
  let error;
  if (!isPassword(value.trim())) {
    error =
      "Password must be 8 characters long and contain at least one uppercase letter, one lowercase letter, one special character (@$!%*#?&_ allowed) and one number";
  }
  return error;
}

export function confirmPassword(pw, cnfPw) {
  let error;
  if (pw !== cnfPw) {
    error = "Passwords do not match";
  }
  return error;
}

export function tel(value) {
  let error;
  if (!isTel(value.trim())) {
    error = "Invalid phone number";
  }
  return error;
}

export function email(value) {
  return value && !isEmail(value.trim()) ? "Invalid email" : null;
}

function isDirty(value) {
  return value || value === 0;
}

export function required(requiredFields, values) {
  return requiredFields.reduce(
    (fields, field) => ({
      ...fields,
      ...(isDirty(values[field]) ? undefined : { [field]: "Required" }),
    }),
    {}
  );
}

function isOtp(string) {
  const re = /^\d{6}$/;
  return re.test(string);
}

export function otpCheck(value) {
  let error;
  if (!isOtp(value.trim())) {
    error = "Please enter 6 digit OTP";
  }
  return error;
}
