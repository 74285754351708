import { Box } from '@mui/material'
import React from 'react'

export default function LoadingPage() {
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',

    }}>
        <img src="/loading.gif" alt="loading" />
        
    </Box>
  )
}
