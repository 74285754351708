import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  Dialog,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import LinkBox from "../modules/components/ManageLinks/LinkBox";
import LoggedInAppBar from "../modules/views/LoggedInAppBar";
import withRoot from "../modules/withRoot";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Element, scroller } from "react-scroll";

import { Edit, Email, Phone, Save, Done, Visibility, KeyboardReturn } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ContactBox from "../modules/components/ManageLinks/ContactBox";
import AppFooter from "../modules/views/AppFooter";
import Snackbar from "../modules/components/Snackbar";
import ThemeButton from "../modules/components/Button";
import { ImageUpload } from "../modules/components/ManageLinks/ImageUpload";

import LoadingPage from "./LoadingPage";
import { getProfile, patchProfile, patchProfile2 } from "../data/profile";
import ProtectedLoggedIn from "../modules/components/protected/ProtectedLoggedIn";
import ViewLinksPreview from "../modules/components/ViewLinks/ViewLinksPreview";


const clientUrl = "https://www.ana3d.in";
const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirecton: "column",
};

const cardWidth = {
  xs: 300,
  sm: 400,
  md: 500,
  lg: 550,
};


const iconStyle = {
  height: {
    xs: 20,
    md: 20,
  },
  width: {
    xs: 20,
    md: 20,
  },
  marginRight: {
    xs: 0,
    // md: "15px"
  },
};
function ManageLinks() {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [links, setLinks] = React.useState([]);
  const [editBio, setEditBio] = React.useState(false);
  const [editPhoto, setEditPhoto] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState();
  const [previewData, setPreviewData] = React.useState({});
  const [preview, setPreview] = React.useState(false);
  const [imagePath, setImagePath] = React.useState(
    "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
  );
  const [bio, setBio] = React.useState("");
  const [editName, setEditName] = React.useState(false);
  const [name, setName] = React.useState("");
  const [showContactInfo, setShowContactInfo] = React.useState(false);
  const [contactInfo, setContactInfo] = React.useState({});
  const [changesMade, setChangesMade] = React.useState(false);
  const [editingLink, setEditingLink] = React.useState(-1);
  const [pictureRemoved, setPictureRemoved] = React.useState(false);
  const [entireProfile, setEntireProfile] = React.useState({});
  const [openCrop, setOpenCrop] = React.useState(false);
  const changeEditingLink = (id) => {
    setEditingLink(id);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    setChangesMade(true);
    const items = Array.from(links);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLinks(items);
  }

  const deleteLink = (id) => {
    const newLinks = links.filter((link) => link.id !== id);
    setLinks(newLinks);
  };

  const changeLinkStatus = (id, checked) => {
 
    const newLinks = links.map((link) => {
      if (link.id === id) {
        link.active = checked;
      }
      return link;
    });
    setLinks(newLinks);
  };
  const changeLinkTitle = (id, title) => {
    const newLinks = links.map((link) => {
      if (link.id === id) {
        link.title = title;
      }
      return link;
    });
    setLinks(newLinks);
  };
  const changeLinkUrl = (id, url) => {
    const newLinks = links.map((link) => {
      if (link.id === id) {
        link.url = url;
      }
      return link;
    });
    setLinks(newLinks);
  };
  // const changeLinkRedirect = (id, checked) => {

  //   const newLinks = links.map((link) => {
  //     if (link.id === id) {
  //       link.redirect.active = checked;
  //     } else {
  //       link.redirect.active = false;
  //     }

  //     return link;
  //   });
  //   setLinks(newLinks);
  // };

  const changeContactInfoStatus = (checked) => {
    const newContactInfo = {
      ...contactInfo,
      active: checked,
    };
    setContactInfo(newContactInfo);
  };

  const handleAddLink = () => {
    const newLinks = [
      {
        title: "",
        url: "",
        active: true,
        id: links.length + 1,
      },
      ...links,
    ];
    setLinks(newLinks);
    scrollToLink(links.length + 1);
  };
  const scrollToLink = (id) => {
    scroller.scrollTo("links", {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -250, // Scrolls to element + 50 pixels down the page
    });
  };

  const changePreviewData = () => {
    const newPreviewData = {
      ...previewData,
      links,
      contact: {
        ...contactInfo,
        active: contactInfo.active,

      },
      bio,
      displayName: name,
    };
    setPreviewData(newPreviewData);
  }

  const handleSave = async () => {
    setChangesMade(false);
    setEditingLink(-1);
    let formData = new FormData(); //formdata object
    selectedFile && formData.append("picture", selectedFile); //append the values with key, value pair
    !selectedFile && pictureRemoved && formData.append("picture", "removed");
    formData.append("contact", JSON.stringify(contactInfo));
    formData.append("links", JSON.stringify(links));
    formData.append("displayName", name);
    formData.append("bio", bio);
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await patchProfile2(
      localStorage.getItem("token"),
      user.email,
      formData
    );

    if (!response.data) {
      alert(
        "Something went wrong while saving changes. Please try again later."
      );
    }
  };

  const getData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getProfile(
      localStorage.getItem("token"),
      user.email
    );


    if (response) {
      setDataLoaded(true);
      response.bio && setBio(response.bio);
      setName(response.displayName);
      response.profilePicture &&
        setImagePath(response.profilePicture.publicurl);
      response.links && setLinks(response.links);
      response.contact &&
        response.contact.title &&
        setContactInfo(response.contact);
      response.contact && response.contact.title && setShowContactInfo(true);
      !response.bio && setBio("Add your bio here");
      setEntireProfile(response);
      setPreviewData(response);
    } else {
      alert("Something went wrong");
    }
  };

  const displayFab = () => {
    const fabStyle = { position: "fixed", 
    bottom: changesMade ? 100 : 16,
    left: 25,
   };
    if (preview) {
      return <Fab
      variant="extended"
      color="secondary"
      sx={{
        position: "fixed",
        bottom: 16,
        left: 25,
      }}
      onClick={() => setPreview(false)}
    >
      <KeyboardReturn sx={{ mr: 1 }} />
      Return to Edit
    </Fab>
    } else {
      return <Fab
      variant="extended"
      color="secondary"
      sx={fabStyle}
      onClick={() => setPreview(true)}
    >
      <Visibility sx={{ mr: 1 }} />
      Preview
    </Fab>
    }
  }
  React.useEffect(() => {

  }, [links]);

  useEffect(() => {
    getData();
  }, []);

  
  

  useEffect(() => {
    changePreviewData();
  }, [links, contactInfo, bio, name, changesMade]);

  return (
    <ProtectedLoggedIn>
    
      {dataLoaded ? (
        <div
          style={{
            backgroundColor: "#F0F0F0",
          }}
        >
          <LoggedInAppBar />

          <React.Fragment>
            <Grid container>
              <Grid item xs={12} md={6}>
                {
                  preview && fullScreen ? <ViewLinksPreview previewData={previewData} /> : <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 10,
                    minHeight: {
                      xs: "65vh",
                      sm: "60vh",
                      md: "60vh",
                      lg: "55vh",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Box sx={{ ...centerStyle, flexDirection: "column" }}>
                      <ImageUpload
                        imagePath={imagePath}
                        setPictureRemoved={setPictureRemoved}
                        setImagePath={setImagePath}
                        setSelectedFile={setSelectedFile}
                        setChangesMade={setChangesMade}
                        setPreviewData={setPreviewData}
                        previewData={previewData}
                        openCrop={openCrop}
                        setOpenCrop={setOpenCrop}
                      />
                    </Box>
                  </Grid>
    
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: cardWidth,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {editName ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          label="Name"
                          variant="standard"
                          size="large"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setChangesMade(true);
                          }}
                          focused={editName}
                          sx={{
                            width: "100%",
                            marginBottom: "20px",
                            height: "100%",
                            width: "100%",
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() => setEditName(false)}
                                sx={{
                                  color: "primary.main",
                                }}
                              >
                                <Done />
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            textAlign: "center",
                            textTransform: "none",
                            marginBottom: "20px",
                          }}
                        >
                          {name}
                          <IconButton
                            onClick={() => {
                              setEditName(!editName);
                              setEditingLink(-1);
                            }}
                            sx={{
                              marginLeft: {
                                xs: "2px",
                                md: "5px",
                              },
                            }}
                          >
                            <Edit
                              sx={{
                                ...iconStyle,
                              }}
                            />
                          </IconButton>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: cardWidth,
                    }}
                  >
                    {editBio ? (
                      <Box>
                        <TextField
                          id="outlined-multiline-static"
                          label="Bio"
                          multiline
                          rows={4}
                          defaultValue={bio}
                          variant="standard"
                          fullWidth
                          onChange={(e) => {
                            setBio(e.target.value);
                            setChangesMade(true);
                          }}
                          sx={{
                            marginBottom: "5px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "20px",
                            marginTop: "5px",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              setEditBio(false);
                            }}
                            label="Save"
                            size="small"
                          >
                            <Done
                              size="small"
                              sx={{
                                marginRight: "5px",
                                height: "20px",
                                width: "20px",
                                marginBottom: "5px",
                              }}
                            />{" "}
                            Save
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "center",
                          textTransform: "none",
                          marginBottom: "20px",
                        }}
                      >
                        {bio}
    
                        <IconButton
                          onClick={() => {
                            setEditBio(true);
                            setEditingLink(-1);
                            if (bio === "Add your bio here") {
                              setBio("");
                            }
                          }}
                          sx={{
                            marginLeft: {
                              xs: "2px",
                              md: "10px",
                            },
                          }}
                        >
                          <Edit
                            sx={{
                              ...iconStyle,
                            }}
                          />
                        </IconButton>
                      </Typography>
                    )}
                  </Grid>
    
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: cardWidth,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        md={showContactInfo ? 12 : 6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: {
                            xs: "center",
                            md: "flex-start",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            mb: 4,
                            width: {
                              xs: "100%",
                              md: showContactInfo ? "100%" : "95%",
                            },
                            borderRadius: "25px",
                            p: 1,
                          }}
                          onClick={() => {
                            handleAddLink();
                          }}
                        >
                          Add New Link
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={showContactInfo ? 12 : 6}
                        sx={{
                          display: !showContactInfo ? "flex" : "none",
                          justifyContent: {
                            xs: "center",
                            md: "flex-end",
                          },
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            mb: 4,
                            width: {
                              xs: "100%",
                              md: "95%",
                            },
                            borderRadius: "25px",
                            p: 1,
                          }}
                          onClick={() => {
                            setShowContactInfo(true);
                            setContactInfo({
                              address: "",
                              email: "",
                              phone: "",
                            });
                          }}
                        >
                          Add Contact Info
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
    
                  <Grid item xs={12}>
                    {showContactInfo ? (
                      <ContactBox
                        contactInfo={contactInfo}
                        setContactInfo={setContactInfo}
                        setShowContactInfo={setShowContactInfo}
                        setChangesMade={setChangesMade}
                        active={contactInfo.active}
                        changeContactInfoStatus={changeContactInfoStatus}
                        changeEditingLink={changeEditingLink}
                      />
                    ) : null}
                    <Element name="links">
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="links">
                          {(provided) => (
                            <Grid
                              container
                              spacing={2}
                              direction="column"
                              alignItems={"center"}
                              justifyContent="center"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {links.map((link, index) => {
                                return (
                                  <Draggable
                                    key={link.id}
                                    draggableId={link.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <Grid
                                        item
                                        xs={12}
                                        id={link.id.toString()}
                                        sx={{ ...centerStyle }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <LinkBox
                                          title={link.title}
                                          url={link.url}
                                          clicks={link.clicks}
                                          deleteLink={deleteLink}
                                          id={link.id}
                                          setChangesMade={setChangesMade}
                                          active={link.active}
                                          // redirect={link.redirect.active}
                                          // changeRedirectStatus={changeLinkRedirect}
                                          changeLinkStatus={changeLinkStatus}
                                          changeLinkTitle={changeLinkTitle}
                                          changeLinkUrl={changeLinkUrl}
                                          changeEditingLink={changeEditingLink}
                                          editingLink={editingLink}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Element>
                  </Grid>
                </Grid>
                }
              
              </Grid>
              <Grid item xs={0} md={6} sx={{
               borderLeft: {
                xs: "0px",
                md: "0.5px solid #e0e0e0",
               }

              }}>
                  <ViewLinksPreview previewData={previewData}/>
              </Grid>
            </Grid>
            
            {/* <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!changesMade}
            message="I love snacks"
          >
            <Alert onClose={handleSave} severity="success" sx={{ width: "100%" }}>
              This is a success message!
            </Alert>
          </Snackbar> */}
            {
              fullScreen ? displayFab() : <a
              href={`${clientUrl}/view/${entireProfile._id.toString()}`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
              <Fab
                variant="extended"
                color="secondary"
                sx={{ position: "fixed", bottom: 16, left: 25 }}
              >
                <CallMadeIcon sx={{ mr: 1 }} />
                My Page
              </Fab>
            </a>
            }
            
            {
              preview ? null : <Snackbar
              open={changesMade}
              autoHideDuration={6000}
              message="You have unsaved changes!"
              closeFunc={handleSave}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              sx={{
                visibility: openCrop ? "hidden" : "visible",
              }}
              action={
                <ThemeButton
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleSave}
                  sx={{
                    ml: {
                      xs: 5,
                      md: 30,
                    },
                    mr: 1,
                    my: 1,
                  }}
                >
                  {" "}
                  Save{" "}
                </ThemeButton>
              }
            />
            }
            {
              preview ? null : <div
              style={{
                marginTop: "5%",
              }}
            >
              <AppFooter mode="light" />
            </div>
            }
            
          </React.Fragment>
        </div>
      ) : (
        <LoadingPage
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        />
      )}
    </ProtectedLoggedIn>
  );
}

export default withRoot(ManageLinks);
