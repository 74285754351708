import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://mui.com/">
        A&A 3D
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function PublicFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex' }}
    >
      <Container sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       
        <a href="https://www.ana3d.in/" target="_blank" rel="noreferrer">
        <img src="/Web.png" alt="Web" height="100" />
        </a>


      </Container>
    </Typography>
  );
}
