import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InterestsIcon from '@mui/icons-material/Interests';
import Icon from '@mdi/react';
import { mdiTwitch, mdiSpotify, mdiWhatsapp, mdiSend, mdiSnapchat,  } from '@mdi/js';
import { 
  FaDiscord, FaSteam, FaGoogleDrive, FaFacebookMessenger, FaGithub,
  FaSkype, FaVimeo, FaDropbox, FaTelegram, FaAmazon, FaTumblr, FaBehance
} from "react-icons/fa";
import { SiGooglemaps, SiZoom, SiFreelancer, SiFiverr, SiUpwork } from "react-icons/si";

const iconStyle = {
  color: "grey"
}
function IconsManage({socialMedia, size}) {

  const socialMediaIcons = {
    instagram: <InstagramIcon style={iconStyle} />,
    facebook: <FacebookIcon style={iconStyle} />,
    linkedin: <LinkedInIcon style={iconStyle} />,
    pinterest: <PinterestIcon style={iconStyle} />,
    reddit: <RedditIcon style={iconStyle} />,
    twitter: <TwitterIcon style={iconStyle} />,
    youtube: <YouTubeIcon style={iconStyle} />,
    twitch: <Icon path={mdiTwitch} size={1} color="grey" />,
    spotify: <Icon path={mdiSpotify} size={1} color="grey" />,
    snapchat: <Icon path={mdiSnapchat} size={1} color="grey" />,
    whatsapp: <Icon path={mdiWhatsapp} size={1} color="grey" />,
    discord: <FaDiscord color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    steam: <FaSteam color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    drive: <FaGoogleDrive color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    maps: <SiGooglemaps color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    messenger: <FaFacebookMessenger color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    github: <FaGithub color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    skype: <FaSkype color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    vimeo: <FaVimeo color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    zoom: <SiZoom color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    dropbox: <FaDropbox color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    freelancer: <SiFreelancer color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    fiverr: <SiFiverr color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    upwork: <SiUpwork color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    telegram: <FaTelegram color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    amazon: <FaAmazon color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    tumblr: <FaTumblr color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    behance: <FaBehance color="grey" style={{
      fontSize: "1.5rem",
    }} />,
    other: <InterestsIcon style={iconStyle} />,
  }
  
  
    return socialMediaIcons[socialMedia]

  
}

export default IconsManage