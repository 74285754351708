import { Container, Grid, Box, Typography, Card } from "@mui/material";
import Button from "../modules/components/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import LinkBox from "../modules/components/ManageLinks/LinkBox";
import PublicAppBar from "../modules/views/PublicAppBar";
import withRoot from "../modules/withRoot";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import AppFooter from "../modules/views/AppFooter";
import Particle from "../modules/components/ViewLinks/Particle";
import PublicFooter from "../modules/views/PublicFooter";
import ContactInfo from "../modules/components/ViewLinks/ContactInfo";
import { clickedLink, getProfileById } from "../data/profile";
import LoadingPage from "./LoadingPage";
import IconsView from "../modules/components/ViewLinks/IconsView";


import { Diversity1 } from "@mui/icons-material";
import ProfileMetaDecorator from "../modules/components/util/ProfileMetaDecorator";
const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirecton: "column",
};

const cardWidth = {
  xs: 300,
  sm: 400,
  md: 500,
  lg: 550,
};
const sampleLinks = [
  {
    title: "Google",
    url: "https://www.google.com",
    id: 1,
    clicks: 23,
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com",
    id: 2,
    clicks: 15,
  },
  {
    title: "Twitter",
    url: "https://www.twitter.com",
    id: 3,
    clicks: 45,
  },
  {
    title: "Reddit",
    url: "https://www.reddit.com",
    id: 4,
    clicks: 35,
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com",
    id: 5,
    clicks: 42,
  },
  {
    title: "Youtube",
    url: "https://www.youtube.com",
    id: 6,
    clicks: 88,
  },
  {
    title: "TikTok",
    url: "https://www.tiktok.com",
    id: 7,
    clicks: 55,
  },
  {
    title: "Twitch",
    url: "https://www.twitch.com",
    id: 8,
    clicks: 25,
  },
  {
    title: "Amazon",
    url: "https://www.amazon.com",
    id: 9,
    clicks: 54,
  },
  {
    title: "Walmart",
    url: "https://www.walmart.com",
    id: 10,
    clicks: 20,
  },
];

const sampleContactInfoInit = {
  email: "esorthe7@gmail.com",
  phone: "9920325295",
  address:
    "C3/210, Ashiana CHS, Mapkhan Nagar, Marol Naka, Andheir-East, Mumbai-400059",
  title: "Contact Us",
};

const iconStyle = {
  height: {
    xs: 20,
    md: 20,
  },
  width: {
    xs: 20,
    md: 20,
  },
  marginRight: {
    xs: 0,
    // md: "15px"
  },
};
function ViewLinks() {
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const [title, setTitle] = React.useState("A&A Prints");
  const [description, setDescription] = React.useState("3D Printing Services");
  const [socialMedia, setSocialMedia] = React.useState("");
  const { userId } = useParams();

  const setDataFn = async () => {
    const response = await getProfileById(userId);
    if (response.data) {
      setData(response.data);
      setTitle(response.data.displayName);
      setDescription(response.data.bio);
      // let redirect = null;
      // response.data.links.forEach((link) => {
      //   if(link.redirect.active) {
      //     redirect = link.url;
      //   }
      // })

      // if(redirect) {
      //   navigate(redirect);
      // }
    } else {

      navigate("/404");
    }
  };
  const socialMediaNames = [
    "instagram",
    "facebook",
    "linkedin",
    "pinterest",
    "reddit",
    "twitter",
    "youtube",
    "twitch",
    "spotify",
    "snapchat",
    "whatsapp",
    "discord",
    "steam",
    "drive",
    "maps",
    "messenger",
    "github",
    "skype",
    "vimeo",
    "zoom",
    "dropbox",
    "freelancer",
    "fiverr",
    "upwork",
    "telegram",
    "amazon",
    "tumblr",
    "behance",
    "other"
  ];
  const identifySocialMedia = (url) => {
    let socialMedia = "other";
    socialMediaNames.forEach((name) => {
      if (url.includes(name)) {
        socialMedia = name;
      }
    });
    return socialMedia;
  };

  useEffect(() => {
    setDataFn();
  }, []);
  // <ProfileMetaDecorator title={title} description={description}>
  return Object.keys(data).length > 0 ? (
    <div
      style={{
        backgroundImage: `url(${
          data && data.profilePicture && data.profilePicture.publicurl
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",        
        margin: 0,
        padding: 0,
      }}
    >
     
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(30px)",
          WebkitBackdropFilter: "blur(30px)",
          margin: 0
        }}
      >
        <React.Fragment>
          <Box
            sx={{
              ...centerStyle,
              pt: {
                xs: 0,
                sm: 0,
                md: 8,
              },
            }}
          >
            <Card
              sx={{
                width: {
                  xs: "100%",
                  sm: "80%",
                  md: "60%",
                },
                borderRadius: "20px",
                background: "none"
              }}
              elevation={0}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  pt: 8,
                  pb: 8,
                }}
              >
                <Grid item xs={12}>
                  <Box sx={{ ...centerStyle, flexDirection: "column" }}>
                    <img
                      src={data.profilePicture && data.profilePicture.publicurl}
                      alt="profile picture"
                      width="200px"
                      height="200px"
                      style={{
                        borderRadius: "50%",

                        marginBottom: "20px",
                      }}
                    ></img>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    width: cardWidth,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign: "center",
                      textTransform: "none",
                      marginBottom: "20px",
                      color: "white",
                    }}
                  >
                    {data.displayName}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    width: cardWidth,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      textTransform: "none",
                      marginBottom: "20px",
                      color: "#f4f4f4",
                    }}
                    fontWeight="400"
                  >
                    {data.bio}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: cardWidth,
                    ...centerStyle,
                  }}
                >
                  <Grid container>
                    {data.contact.active && (
                      <Grid
                        item
                        xs={12}
                        sx={{ marginBottom: "30px", ...centerStyle }}
                      >
                        {data.contact.active && (
                          <ContactInfo contactInfo={data.contact} />
                        )}
                      </Grid>
                    )}
                    {data.links.map(
                      (link) =>
                        link.active && (
                          <Grid
                            item
                            xs={12}
                            sx={{ marginBottom: "30px", ...centerStyle }}
                            key={link.id}
                            onClick={async () => {
                              const response = await clickedLink(
                                userId,
                                link.id
                              );
                              
                            }}
                          >
                            <a
                              href={link.url}
                              style={{
                                textDecoration: "none",
                                width: "100%",
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  
                                  fontSize: "1.5rem",
                                
                                  
                                }}
                                variant="contained"
                                color="primary"
                             
                                 
                                
                              > 
                                
                                <IconsView
                                    socialMedia={identifySocialMedia(link.url)}
                                    style={{
                                      marginRight: "auto",
                                    }}
                                  /> 
                          
                                
                                  <span style={{
                                    marginRight: "auto",
                                  }}>
                                  {link.title}
                                  </span>
                                   
                              </Button>
                            </a>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>

          <div
            style={{
              marginTop: "5%",
            }}
          >
            <PublicFooter />
          </div>
        </React.Fragment>
      </div>
    </div>
  ) : (
    <LoadingPage />
  )
  }
 
  
 


export default withRoot(ViewLinks);
