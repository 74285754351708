import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import { Email, Instagram, Phone, PhoneRounded } from "@mui/icons-material";
import { motion } from "framer-motion";
const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
 
  my: 4,
  fontSize: 55,
  color: "white",
};

function GetInTouch3() {
  return (
    <motion.div
    initial={{x: 100, opacity: 0.5}}
    animate={{ x: 0}}
    transition={{ duration: 2 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
  >
     <div
      style={{
        backgroundImage: `url(${
          `/3dprinter.jpg`
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",        
        margin: 0,
    
      }}
    >
     
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(30px)",
          WebkitBackdropFilter: "blur(30px)",
          margin: 0
        }}
      >
    <Box
      component="section"
      sx={{
        display: "flex",
        // bgcolor: "secondary.light",
        overflow: "hidden",
        // backgroundSize: "cover",
        // backgroundImage:
        //   "url('/geometric.png')",
      }}
      id="get-in-touch"
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }} color="white">
          Get In Touch Now!
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Phone sx={image}/>
             
                <Typography variant="h5" align="center" color="white">
                  Abner Remedios
                </Typography>
                
                <a href="tel:+918452959394" style={{textDecoration: 'none'}}>
                <Typography variant="h5" align="center" sx={number} color="white">
                  +91 8452959394
                </Typography>
                </a>
                <Typography variant="h5" align="center" color="white">
                  Androse Fernandes
                </Typography>
                <a href="tel:+919076076126" style={{textDecoration: 'none'}}>
                <Typography variant="h5" align="center" sx={number} color="white">
                  +91 9076076126
                </Typography>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Instagram sx={image} />
                <Typography variant="h5" align="center" color="white">
                  Our Instagram
                </Typography>
                <a
                  href="https://www.instagram.com/the.ana.3d/"
                  style={{
                    textDecorationColor: "#fa2a3e",
                   
                  }}
                  className="underlined-link"
                >
                  <Typography variant="h5" align="center" sx={number}>
                    @the.ana.3d
                  </Typography>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Email sx={image} />
                <Typography variant="h5" align="center" color="white">
                  Our Email
                </Typography>
                <a
                  href="mailto: anaprints2202@gmail.com"
                  style={{
                    textDecorationColor: "#fa2a3e",
                  }}
                  className="underlined-link"
                >
                  <Typography variant="h5" align="center" sx={number} >
                    anaprints2202@gmail.com
                  </Typography>
                </a>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
    </div>
    </div>
    </motion.div>
  );
}

export default GetInTouch3;
