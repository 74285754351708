import { useState, useEffect } from "react";
import TextField from "../TextField";
import Typography from "../Typography";
import { Edit } from "@mui/icons-material";
import { IconButton, ListItemButton, ListItemText, List, Dialog } from "@mui/material";
import ImageUploading from "react-images-uploading";
import CropEasy from "./CropEasy";

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirecton: "column",
};
const iconStyle = {
  height: {
    xs: 20,
    md: 20,
  },
  width: {
    xs: 20,
    md: 20,
  },
  marginRight: {
    xs: 0,
    // md: "15px"
  },
};
export const ImageUpload = ({
  imagePath,
  setImagePath,
  selectedFile,
  setSelectedFile,
  setChangesMade,
  setPictureRemoved,
  setPreviewData,
  previewData,
  setOpenCrop,
  openCrop,
}) => {
  const [preview, setPreview] = useState();
  const [images, setImages] = useState([]);
  
  const [openDialog, setOpenDialog] = useState(false);
  const handleChange = (e) => {
    const file = e.target.files[0];

  
    if (file) {

      setSelectedFile(file);
      setImagePath(URL.createObjectURL(file));
      setChangesMade(true);
      setPictureRemoved(false);
      setPreviewData({
        ...previewData,
        profilePicture: {
          publicurl: URL.createObjectURL(file),
        },
      });

      setOpenCrop(true);
    }
    setOpenDialog(false);
  };
  const handleChangePic = () => {
    setOpenDialog(false);
  }
  const handleRemovePic = () => {
    setImages([]);
                setImagePath(
                  "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                );
                setPictureRemoved(true);
                setSelectedFile(null);
                setChangesMade(true);
                setPreviewData({
                  ...previewData,
                  profilePicture: {
                    publicurl:
                      "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg",
                  },
                });
    setOpenDialog(false);

  }
  return (
    <div>
      <Dialog open={openCrop} onClose={() => setOpenCrop(false)}>
        <CropEasy
          photoURL={imagePath}
          setOpenCrop={setOpenCrop}
          setPhotoURL={setImagePath}
          setFile={setSelectedFile}
          setChangesMade={setChangesMade}
          setPictureRemoved={setPictureRemoved}
          setPreviewData={setPreviewData}
          previewData={previewData}

        />
      </Dialog>
      
          <div className="upload__image-wrapper" onClick={() => {setOpenDialog(true)}}>
            <div className="pfp-container">
              <img
                src={imagePath}
                style={{
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                className="pfp"
              />

              <div className="overlay" style={{ ...centerStyle }}>
                <IconButton
                  sx={{ ...iconStyle, opacity: 1 }}
                 onClick={() => {setOpenDialog(true)}}
                >
                  <Edit
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              </div>
            </div>
            {/* <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                cursor: "pointer",
                marginTop: "10px",
                textDecoration: "underline",
              }}
              className="underlined-link"
              onClick={() => {
                setImages([]);
                setImagePath(
                  "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                );
                setPictureRemoved(true);
                setSelectedFile(null);
                setChangesMade(true);
                setPreviewData({
                  ...previewData,
                  profilePicture: {
                    publicurl:
                      "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg",
                  },
                });
              }}
            >
              Clear Profile Picture
            </Typography> */}
          </div>
          <Dialog onClose={() => setOpenDialog(!openDialog)} open={openDialog}>
      
            <List sx={{ pt: 0 }}>
            <label htmlFor="profilePhoto">
          <input
            accept="image/*"
            id="profilePhoto"
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
          />
              <ListItemButton >
                <ListItemText primary="Change Profile Picture" />
              </ListItemButton>
              </label>
              <ListItemButton onClick={handleRemovePic} sx={{
                pb: 0
              }}>
                <ListItemText primary="Remove Profile Picture" />
              </ListItemButton>
            </List>
          </Dialog>
      
      

      {/* <ImageUploading
        multiple
        value={images}
        onChange={onUpload}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          
          <div className="upload__image-wrapper ">
            
            <div className="pfp-container">
              <img
                src={images.length > 0 ? images[0].data_url : imagePath}
                onClick={onImageUpload}
                {...dragProps}
                style={{
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                className="pfp"
              />
              <div
                className="overlay"
                style={{ ...centerStyle }}
                onClick={() => {
                  imageList.length > 0 ? onImageUpdate(0) : onImageUpload();
                }}
                {...dragProps}
              >
                <IconButton
                  sx={{ ...iconStyle, opacity: 1 }}
                 
                >
                  <Edit
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              </div>
            </div>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                cursor: "pointer",
                marginTop: "10px",
                textDecoration: "underline",
              }}
              className="underlined-link"
              onClick={() => {
                setImages([]);
                setImagePath("https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg")
                setPictureRemoved(true);
                setSelectedFile(null);
                setChangesMade(true);
                setPreviewData({
                  ...previewData,
                  profilePicture: {
                    publicurl: "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg",
                  }
                });
                
                onImageRemove(0);
              }}
            >
              Clear Profile Picture
            </Typography>

          </div>
        )}
      </ImageUploading> */}
    </div>
  );
};
