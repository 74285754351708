import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../../modules/components/Typography";
import AppFooter from "../../modules/views/AppFooter";
import AppAppBar from "../../modules/views/AppAppBar";
import AppForm from "../../modules/views/AppForm";
import { password, otpCheck, required } from "../../modules/form/validation";
import RFTextField from "../../modules/form/RFTextField";
import FormButton from "../../modules/form/FormButton";
import FormFeedback from "../../modules/form/FormFeedback";
import withRoot from "../../modules/withRoot";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../data/auth";
function ResetPassword() {
  const [sent, setSent] = React.useState(false);
  const [pwChanged, setPwChanged] = React.useState(false);
  const [error, setError] = React.useState("");

  const { token } = useParams();
  const validate = (values) => {
    const errors = required(["password"], values);
    if (!errors.password) {
      const passwordError = password(values.password);
      if (passwordError) {
        errors.password = passwordError;
      }
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    setSent(true);
    const response = await resetPassword(e, token);
    if (response.message === "Password reset successful") {
      setPwChanged(true);
    } else {
      setError(response.response.data.message);

      setSent(false);
    }
  };

  const linkEl = () => (<a href="sign-in" style={{ textDecorationColor: "black", color: "black" }}>
 Login
</a>)

  return (
    <React.Fragment>
      <AppAppBar />
      {pwChanged ? (
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Password Successfully Changed!
            </Typography>
            <a
              href="/sign-in"
              style={{ textDecorationColor: "black", color: "black" }}
            >
              <Typography variant="body1" align="center">
                {"Click here to login"}
              </Typography>
            </a>
            <img
              src="/email-sent-transparent.gif"
              alt="password Sent"
              width="50%"
              style={{
                marginTop: "30px",
                alignSelf: "center",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </React.Fragment>
        </AppForm>
      ) : (
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Reset Password
            </Typography>
            <Typography variant="body2" align="center">
              {"Set a New Password for your Account"}
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Field
                  autoFocus
                  autoComplete="password"
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="New Password"
                  margin="normal"
                  name="password"
                  required
                  size="large"
                  type="password"
                />
                <Typography variant="body1" align="center" color="error">
                  {error}
                </Typography>
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Set Password"}
                </FormButton>
              </Box>
            )}
          </Form>
        </AppForm>
      )}

      <AppFooter mode="light" />
    </React.Fragment>
  );
}

export default withRoot(ResetPassword);
