import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Tooltip from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/material";

const iconStyle = {
  height: 15,
  width: 15,
};

const sampleContactInfo = {
  email: "abc@google.com",
  phone: "1234567890",
  address: "02, Om Villa, Plot no. 111, Sector 11, Gandhinagar, Gujarat, India",
  title: "Contact Us",
};
function ContactBox({
  setContactInfo,
  contactInfo,
  setShowContactInfo,
  setChangesMade,
  active,
  changeContactInfoStatus,
  changeEditingLink,
}) {
  const [addressState, setAddressState] = React.useState("");
  const [emailState, setEmailState] = React.useState("");
  const [phoneState, setPhoneState] = React.useState("");
  const [titleState, setTitleState] = React.useState("");
  const [editAddressState, setEditAddressState] = React.useState(false);
  const [editEmailState, setEditEmailState] = React.useState(false);
  const [editPhoneState, setEditPhoneState] = React.useState(false);
  const [editTitleState, setEditTitleState] = React.useState(false);

  return (
    <Card
      sx={{
        display: "flex",
        width: {
          xs: 300,
          sm: 400,
          md: 500,
          lg: 550,
        },
        mb: 2,
        borderRadius: 7,
        py: 2,
        px: 2,
      }}
      onClick={() => {
        changeEditingLink(-1);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <CardContent
            sx={{
              width: "100%",
            }}
          >
            <Typography variant="subtitle2">Contact Information</Typography>
            <ClickAwayListener onClickAway={() => setEditTitleState(false)}>

            <TextField
              id="contact-title"
              label="Title"
              defaultValue={contactInfo.title}
              variant="standard"
              fullWidth
              onClick={() => {
                setEditTitleState(true);
              }}
              size="small"
              onChange={(e) => {
                setTitleState(e.target.value);
                setChangesMade(true);
                setContactInfo({ ...contactInfo, title: e.target.value });
              }}
              shrink
              focused={editTitleState}
              InputProps={{
                readOnly: !editTitleState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editTitleState ? "hidden" : "visible",
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditTitleState(true)}
                      size="small"
                    >
                      {editTitleState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden",
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setEditAddressState(false)}>
            <TextField
              id="address"
              label="Address"
              defaultValue={contactInfo.address}
              variant="standard"
              fullWidth
              size="small"
              onClick={() => {
                setEditAddressState(true);
              }}
              onChange={(e) => {
                setAddressState(e.target.value);
                setChangesMade(true);
                setContactInfo({ ...contactInfo, address: e.target.value });
              }}
              shrink
              focused={editAddressState}
              multiline
              rows={3}
              sx={{
                mt: 2,
              }}
              InputProps={{
                readOnly: !editAddressState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editAddressState ? "hidden" : "visible",
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditAddressState(true)}
                      size="small"
                    >
                      {editAddressState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden",
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setEditEmailState(false)}>
            <TextField
              id="email"
              label="Email"
              defaultValue={contactInfo.email}
              variant="standard"
              fullWidth
              size="small"
              sx={{
                mt: 2,
              }}
              onClick={() => {
                setEditEmailState(true);
              }}
              onChange={(e) => {
                setEmailState(e.target.value);
                setChangesMade(true);
                setContactInfo({ ...contactInfo, email: e.target.value });
              }}
              shrink
              InputProps={{
                readOnly: !editEmailState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editEmailState ? "hidden" : "visible",
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditEmailState(true)}
                      size="small"
                    >
                      {editEmailState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden",
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setEditPhoneState(false)}>
            <TextField
              id="phone"
              label="Phone"
              defaultValue={contactInfo.phone}
              variant="standard"
              fullWidth
              size="small"
              focused={editPhoneState}
              sx={{
                mt: 2,
              }}
              onClick={() => {
                setEditPhoneState(true);
              }}
              onChange={(e) => {
                setPhoneState(e.target.value);
                setChangesMade(true);
                setContactInfo({ ...contactInfo, phone: e.target.value });
              }}
              shrink
              InputProps={{
                readOnly: !editPhoneState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editPhoneState ? "hidden" : "visible",
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditPhoneState(true)}
                      size="small"
                    >
                      {editPhoneState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden",
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mx: 2,
              pb: 1,
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              title={
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  Active Status
                </Typography>
              }
              placement="bottom"
              arrow
            >
              <Switch
                color="success"
                size="small"
                sx={{
                  mr: 2,
                }}
                checked={active}
                onChange={(event) => {
                  changeContactInfoStatus(event.target.checked);
                  setChangesMade(true);
                }}
              />
            </Tooltip>

            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                setContactInfo({
                  address: "",
                  email: "",
                  phone: "",
                });
                setShowContactInfo(false);
                setEditAddressState(false);
                setEditEmailState(false);
                setEditPhoneState(false);
                setChangesMade(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ContactBox;
