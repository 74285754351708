import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";

function Copyright({ mode }) {
  return (
    <React.Fragment>
      <Box
        sx={{
          color: mode === "light" ? "#000" : "#fff",
          display: "flex",
        }}
      >
        {"©"}
        &nbsp;
        <a
          href="https://ana3d.in/"
          style={{
            textDecorationColor: mode === "light" ? "#000" : "#fff",
          }}
        >
          <Typography
            variant="h6"
            color="secondary"
            sx={{
              color: mode === "light" ? "#000" : "#fff",
              "&:hover": {
                color: mode === "light" ? "primary.light" : "#f4f4f4",
                transition: "0.5s",
              },
            }}
          >
            A&A 3D
          </Typography>
        </a>
        &nbsp;
        {new Date().getFullYear()}
      </Box>
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function AppFooter({ mode }) {
  return (
    <Typography component="footer" sx={{ display: "flex" }}>
      <Container
        sx={{
          my: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" marked="center" gutterBottom>
          <Copyright mode={mode} />
        </Typography>
      </Container>
    </Typography>
  );
}
