import axios from "axios";

const url = "https://ana3d-backend.up.railway.app/api";

export const getProfile = async (token, email) => {
    try {
        const { data } = await axios.get(url + "/DB/profiles/" + email, {
        headers: { 
            Authorization: `Bearer ${token}`,
           
        },
        });
    
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const patchProfile = async (token, email, formData) => {
    try {
        const { data } = await axios.patch(url + "/DB/profiles/" + email, formData, {
        headers: { 
            Authorization: `Bearer ${token}`,
            

        },
        });
 
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const patchProfile2 = async (token, email, formData) => {
    try {
        const { data } = await axios.patch(url + "/profile/update", formData, {
        headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
        });

        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getProfileById = async (id) => {
    try {
        const { data } = await axios.get(url + "/profile/public/" + id);
  
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const clickedLink = async (profileId, linkId) => {
    try {
        const { data } = await axios.patch(url + "/profile/clicks/" + profileId + "/" + linkId);
      
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};
