import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InterestsIcon from '@mui/icons-material/Interests';
import Icon from '@mdi/react';
import { mdiTwitch, mdiSpotify, mdiWhatsapp, mdiSend, mdiSnapchat,  } from '@mdi/js';
import { 
  FaDiscord, FaSteam, FaGoogleDrive, FaFacebookMessenger, FaGithub,
  FaSkype, FaVimeo, FaDropbox, FaTelegram, FaAmazon, FaTumblr, FaBehance
} from "react-icons/fa";
import { SiGooglemaps, SiZoom, SiFreelancer, SiFiverr, SiUpwork } from "react-icons/si";

function IconsView({socialMedia, size, style}) {
  const iconStyle = {
    // color: "primary.main",
    // fontSize: "1.8rem",
    ...style,
  }
  const socialMediaIcons = {
    instagram: <InstagramIcon style={iconStyle} />,
    facebook: <FacebookIcon style={iconStyle} />,
    linkedin: <LinkedInIcon style={iconStyle} />,
    pinterest: <PinterestIcon style={iconStyle} />,
    reddit: <RedditIcon style={iconStyle} />,
    twitter: <TwitterIcon style={iconStyle} />,
    youtube: <YouTubeIcon style={iconStyle} />,
    twitch: <Icon path={mdiTwitch} size={1} style={iconStyle} />,
    spotify: <Icon path={mdiSpotify} size={1}  style={iconStyle} />,
    snapchat: <Icon path={mdiSnapchat} size={1}  style={iconStyle} />,
    whatsapp: <Icon path={mdiWhatsapp} size={1} style={iconStyle}  />,
    discord: <FaDiscord style={iconStyle} />,
    steam: <FaSteam style={iconStyle} />,
    drive: <FaGoogleDrive style={iconStyle} />,
    maps: <SiGooglemaps style={iconStyle} />,
    messenger: <FaFacebookMessenger style={iconStyle} />,
    github: <FaGithub style={iconStyle} />,
    skype: <FaSkype style={iconStyle} />,
    vimeo: <FaVimeo style={iconStyle} />,
    zoom: <SiZoom style={iconStyle} />,
    dropbox: <FaDropbox style={iconStyle}/>,
    freelancer: <SiFreelancer style={iconStyle} />,
    fiverr: <SiFiverr style={iconStyle} />,
    upwork: <SiUpwork style={iconStyle} />,
    telegram: <FaTelegram style={iconStyle} />,
    amazon: <FaAmazon style={iconStyle} />,
    tumblr: <FaTumblr style={iconStyle} />,
    behance: <FaBehance style={iconStyle} />,
    other: <InterestsIcon style={iconStyle} />,

  }
  
    return socialMediaIcons[socialMedia]

  
}

export default IconsView