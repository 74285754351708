import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import Typography from "../components/Typography";
import { motion } from "framer-motion";

const rightLink = {
  fontSize: 16,
  color: "#fff",
  marginLeft: 3,
};

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }} />
          <motion.div
            initial={{ y: -100, opacity: 0.5 }}
            animate={{ y: 0 }}
            transition={{ duration: 2 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <Box
              sx={{
                mt: 3,
                pb: 2,
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                }}
                href="/"
              >
                <img src="/Web2.png" alt="logo" height="60" style={{}} />
              </a>
            </Box>
          </motion.div>
          
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <motion.div
            initial={{ y: -100, opacity: 0.5 }}
            animate={{ y: 0}}
            transition={{ duration: 2 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <a href="/sign-in" style={{  textDecoration: "none" }}>
              <Typography
                variant="h6"
                color="secondary"
                sx={{
                  color: "secondary.main",
                  "&:hover": {
                    color: "#f54555",
                    transition: "0.5s",
                  },
                }}
              >
                Sign In
              </Typography>
            </a>
            </motion.div>
          </Box>
          
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
