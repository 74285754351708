import * as React from "react";
import ProductCategories from "../modules/views/ProductCategories";
import ProductSmokingHero from "../modules/views/ProductSmokingHero";
import AppFooter from "../modules/views/AppFooter";
import ProductHero from "../modules/views/ProductHero";
import GetInTouch3 from "../modules/views/GetInTouch3";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import LoggedInAppBar from "../modules/views/LoggedInAppBar";
import { Helmet } from "react-helmet";

function Index() {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Official Website of A&A 3D. We are a 3D printing company based in Mumbai, India. We provide high quality and precise 3D prints."
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="A&A 3D Prints"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Official Website of A&A 3D. We are a 3D printing company based in Mumbai, India. We provide high quality and precise 3D prints."
          data-react-helmet="true"
        />
        <title>A&A 3D Prints</title>
      </Helmet>
      {localStorage.getItem("token") ? <LoggedInAppBar /> : <AppAppBar />}

      <ProductHero />

      {/* <ProductValues /> */}

      <ProductCategories />
      <div
        style={{
          margin: 0,
          padding: 0,
          backgroundColor: "black",
        }}
      >
        <GetInTouch3 />

        {/* <ProductCTA /> */}
        <ProductSmokingHero />
        <AppFooter mode="dark" />
      </div>
    </React.Fragment>
  );
}

export default withRoot(Index);
