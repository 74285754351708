import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../../modules/components/Typography";
import AppFooter from "../../modules/views/AppFooter";
import AppAppBar from "../../modules/views/AppAppBar";
import AppForm from "../../modules/views/AppForm";
import { required, password, confirmPassword } from "../../modules/form/validation";
import RFTextField from "../../modules/form/RFTextField";
import FormButton from "../../modules/form/FormButton";
import FormFeedback from "../../modules/form/FormFeedback";
import withRoot from "../../modules/withRoot";
import { changePassword } from "../../data/auth";
import { useNavigate } from "react-router-dom";
import LoggedInAppBar from "../../modules/views/LoggedInAppBar";
import ProtectedLoggedIn from "../../modules/components/protected/ProtectedLoggedIn";
function ChangePassword() {
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState(false);

  const navigate = useNavigate();

  const validate = (values) => {
    const errors = required(["oldpassword", "password", "confirmPassword"], values);

    if (!errors.password) {
      const passwordError = password(values.password);
      if (passwordError) {
        errors.password = passwordError;
      }
    }

    if (!errors.confirmPassword) {
      const confirmPasswordError = confirmPassword(values.password, values.confirmPassword);
      if (confirmPasswordError) {
        errors.confirmPassword = confirmPasswordError;
      }
    }
    

    return errors;
  };

  const handleSubmit = async (e) => {
    setSent(true);
 
    const token = localStorage.getItem("token");
    const response = await changePassword(token, {
      oldpassword: e.oldpassword,
      newpassword: e.password,
    });

    if (response.message == "Password changed!") {
      setSent(false);
      navigate("/manage");
    } else {
      setSent(false);
      setError(true);
    }
  };

  return (
    <ProtectedLoggedIn>
      <React.Fragment>
        <LoggedInAppBar />

        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Change Your Password
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Field
                  autoFocus
                  autoComplete="password"
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  type="password"
                  label="Old Password"
                  margin="normal"
                  name="oldpassword"
                  required
                  size="large"
                />
                <Field
                  autoComplete="password"
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  type="password"
                  label="New Password"
                  margin="normal"
                  name="password"
                  required
                  size="large"
                />

                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  margin="normal"
                  name="confirmPassword"
                  required
                  size="large"
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <Typography variant="body2" align="center" color="error">
                  {error ? "Old password is incorrect" : ""}
                </Typography>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Change Password"}
                </FormButton>
              </Box>
            )}
          </Form>
        </AppForm>

        <AppFooter mode="light" />
      </React.Fragment>
    </ProtectedLoggedIn>
  );
}

export default withRoot(ChangePassword);
