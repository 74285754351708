import axios from "axios";

const url = "https://ana3d-backend.up.railway.app/api";

export const getAllUsers = async (token) => {
    try {
        const { data } = await axios.get(url + "/DB/users", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });
       
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const deleteUser = async (token, email) => {
    try {
        const { data } = await axios.delete(url + "/DB/users/" + email, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });

        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};
