import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../../modules/components/Typography";
import AppFooter from "../../modules/views/AppFooter";
import AppAppBar from "../../modules/views/AppAppBar";
import AppForm from "../../modules/views/AppForm";
import { email, otpCheck, required } from "../../modules/form/validation";
import RFTextField from "../../modules/form/RFTextField";
import FormButton from "../../modules/form/FormButton";
import FormFeedback from "../../modules/form/FormFeedback";
import withRoot from "../../modules/withRoot";
import { Link } from "react-router-dom";
import { ContactPageSharp } from "@mui/icons-material";
import { forgotPassword } from "../../data/auth";

function ForgotPassword() {
  const [sent, setSent] = React.useState(false);
  const [codeSent, setCodeSent] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [error, setError] = React.useState('');
  const validate = (values) => {
    const errors = required(["email"], values);
    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }
    return errors;
  };
  const validateCode = (values) => {
    const errors = required(["otp"], values);

    if (!errors.otp) {
      const otpError = otpCheck(values.otp);
      if (otpError) {
        errors.otp = otpError;
      }
    }

    return errors;
  };
  const handleSubmit = async (e) => {
    setSent(true);
    const response = await forgotPassword(e);
    
    // setEmailSent(true);
    if (response.message === "Password reset link sent to your email") {
      setEmailSent(true);

    } else {
      if(response.response.data.message === "User does not exist") {
        setError("User not Found! Please Sign Up");
      } else {
        setError(response.response.data.message);
      }
      setSent(false);
    }
  };

  const handleSubmitCode = (e) => {
    setCodeSent(true);
  };
  return (
    <React.Fragment>
      <AppAppBar />
      {emailSent ? (
        //   <AppForm>
        //   <React.Fragment>
        //     <Typography variant="h3" gutterBottom marked="center" align="center">
        //       Verify Your Email
        //     </Typography>
        //     <Typography variant="body2" align="center">
        //       {"Enter the Code sent to your Email Address"}
        //     </Typography>
        //   </React.Fragment>
        //   <Form
        //     onSubmit={handleSubmitCode}
        //     subscription={{ submitting: true }}
        //     validate={validateCode}
        //   >
        //     {({ handleSubmit: handleSubmit3, submitting }) => (
        //       <Box component="form" onSubmit={handleSubmit3} noValidate sx={{ mt: 6 }}>
        //         <Field
        //           autoFocus
        //           component={RFTextField}
        //           disabled={submitting || codeSent}
        //           fullWidth
        //           label="Code"
        //           margin="normal"
        //           name="otp"
        //           required
        //           size="large"
        //         />
        //         <FormSpy subscription={{ submitError: true }}>
        //           {({ submitError }) =>
        //             submitError ? (
        //               <FormFeedback error sx={{ mt: 2 }}>
        //                 {submitError}
        //               </FormFeedback>
        //             ) : null
        //           }
        //         </FormSpy>
        //         <FormButton
        //           sx={{ mt: 3, mb: 2 }}
        //           disabled={submitting || codeSent}
        //           size="large"
        //           color="secondary"
        //           fullWidth
        //         >
        //           {submitting || codeSent ? 'In progress…' : 'Verify Code'}
        //         </FormButton>
        //       </Box>
        //     )}
        //   </Form>
        // </AppForm>
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Password Reset Link Sent
            </Typography>
            <Typography variant="body1" align="center">
              {"Please check your Email for the Reset Link."}
            </Typography>
            <img
              src="/email-sent-transparent.gif"
              alt="Email Sent"
              width="50%"
              style={{
                marginTop: "30px",
                alignSelf: "center",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </React.Fragment>
        </AppForm>
      ) : (
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Forgot your password?
            </Typography>
            <Typography variant="body2" align="center">
              {"Enter your Email Address below and we'll " +
                "send you a Reset Link."}
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Field
                  autoFocus
                  autoComplete="email"
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <Typography variant="body1" align="center" color="error">
                  {error}
                </Typography>
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? "In progress…" : "Send Link"}
                </FormButton>
              </Box>
            )}
          </Form>
          <a
            href="/sign-in"
            style={{
              color: "black",
              textAlign: "center",
              display: "block",
              marginTop: "20px",
            }}
          >
            Back to Login
          </a>
        </AppForm>
      )}

      <AppFooter mode="light" />
    </React.Fragment>
  );
}

export default withRoot(ForgotPassword);
