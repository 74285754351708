import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import MovingIcon from '@mui/icons-material/Moving';
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Icons from "./IconsManage";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
// import { ToggleButton } from "@mui/material";
const iconStyle = {
  height: 15,
  width: 15,
};
function LinkBox({
  title,
  url,
  clicks,
  id,
  deleteLink,
  setChangesMade,
  changeLinkStatus,
  changeLinkTitle,
  changeLinkUrl,
  changeEditingLink,
  // changeRedirectStatus,
  editingLink,
  active,
  // redirect
}) {
  const [titleState, setTitleState] = React.useState(title);
  const [urlState, setUrlState] = React.useState(url);
  const [editTitleState, setEditTitleState] = React.useState(false);
  const [editUrlState, setEditUrlState] = React.useState(false);
  const [activeState, setActiveState] = React.useState(active);
  const [socialMedia, setSocialMedia] = React.useState("");
  const [linkError, setLinkError] = React.useState(false);
  // const [redirectState, setRedirectState] = React.useState(redirect);

  const checkUrl = (url) => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (regex.test(url)) {
      setLinkError(false);
      return true;
    } else {
      setLinkError(true);
      return false;
    }
  }
  const identifySocialMedia = (url) => {
    if (url.includes("facebook")) {
      setSocialMedia("facebook");
    } else if (url.includes("instagram")) {
      setSocialMedia("instagram");
    } else if (url.includes("linkedin")) {
      setSocialMedia("linkedin");
    } else if (url.includes("pinterest")) {
      setSocialMedia("pinterest");
    } else if (url.includes("reddit")) {
      setSocialMedia("reddit");
    } else if (url.includes("twitch")) {
      setSocialMedia("twitch");
    } else if (url.includes("twitter")) {
      setSocialMedia("twitter");
    } else if (url.includes("youtube")) {
      setSocialMedia("youtube");
    } else if (url.includes("whatsapp")) {
      setSocialMedia("whatsapp");
    }  else if (url.includes("telegram")) {  
      setSocialMedia("telegram");
    } else if (url.includes("snapchat")) {
      setSocialMedia("snapchat");
    } else if (url.includes("spotify")) {
      setSocialMedia("spotify");
    } else if (url.includes("discord")) {
      setSocialMedia("discord");
    } else if (url.includes("steam")) {
      setSocialMedia("steam");
    } else if (url.includes("maps")) {
      setSocialMedia("maps");
    } else if (url.includes("drive")) {
      setSocialMedia("drive");
    } else if (url.includes("github")) {
      setSocialMedia("github");
    } else if (url.includes("messenger")) {
      setSocialMedia("messenger");
    } else if (url.includes("skype")) {
      setSocialMedia("skype");
    } else if (url.includes("zoom")) {
      setSocialMedia("zoom");
    } else if (url.includes("vimeo")) {
      setSocialMedia("vimeo");
    } else if (url.includes("dropbox")) {
      setSocialMedia("dropbox");
    } else if (url.includes("amazon")) {
      setSocialMedia("amazon");
    } else if (url.includes("tumblr")) {
      setSocialMedia("tumblr");
    } else if (url.includes("fiverr")) {
      setSocialMedia("fiverr");
    } else if (url.includes("upwork")) {
      setSocialMedia("upwork");
    } else if (url.includes("freelancer")) {
      setSocialMedia("freelancer");
    } else if (url.includes("behance")) {
      setSocialMedia("behance");
    }
    else
      setSocialMedia("other");
  };

  // const handleRedirectChange = (event) => {
  //   changeEditingLink(id);
  //   setRedirectState(event.target.checked);
  //   changeRedirectStatus(id, event.target.checked);
  //   setChangesMade(true);
    
  // }
  React.useEffect(() => {
    identifySocialMedia(url);
  }, [url]);

  
  return (
    <Card
      sx={{
        display: "flex",
        width: {
          xs: 300,
          sm: 400,
          md: 500,
          lg: 550,
        },
        borderRadius: 7,
        py: 2,
        pl: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DragIndicatorIcon sx={{ color: "#000000", height: 25, width: 25 }} />
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <CardContent
            sx={{
              width: "100%",
            }}
          >
            <ClickAwayListener onClickAway={() => setEditTitleState(false)}>
            <TextField
              id="link-title"
              label="Title"
              defaultValue={title}
              variant="standard"
              fullWidth
              size="small"
              onClick={() => setEditTitleState(true)}
              onChange={(e) => {
                setTitleState(e.target.value);
                changeLinkTitle(id, e.target.value);
                setChangesMade(true);
                changeEditingLink(id);
              }}
              shrink
              focused={editTitleState}
              inputRef={(input) => editTitleState && input && editingLink == id && input.focus()}
              InputProps={{
                readOnly: !editTitleState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editTitleState ? "hidden" : "visible"
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditTitleState(true)}
                      size="small"
                    >
                      {editTitleState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden"
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => {

              setEditUrlState(false)
              }}>
            <TextField
              id="link-url"
              label="URL"
              defaultValue={url}
              variant="standard"
              fullWidth
              size="small"
              sx={{
                mt: 2,
              }}
              onClick={() => setEditUrlState(true)}
              onChange={(e) => {
                if(e.target.value.length > 4) {
                  if (!/^https?/i.test(e.target.value)) {
                  e.target.value = 'http://' + e.target.value;
                }
                }
                
                if (checkUrl(e.target.value)){
                  setUrlState(e.target.value);
                  changeLinkUrl(id, e.target.value);
                  setChangesMade(true);
                  changeEditingLink(id);
                }
                
                
              }}
              shrink
              error={linkError}
              helperText={linkError ? "Invalid URL" : ""}
              inputRef={(input) => editUrlState && input && editingLink == id && input.focus()}
              InputProps={{
                readOnly: !editUrlState,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                    visibility: editUrlState ? "hidden" : "visible",
                  }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setEditUrlState(true)}
                      size="small"
                      sx={{
                        visibility: editUrlState ? "hidden" : "visible",
                      }}
                    >
                      {editUrlState ? (
                        <DoneIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                            visibility: "hidden"
                          }}
                        />
                      ) : (
                        <EditIcon
                          size="small"
                          sx={{
                            ...iconStyle,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </ClickAwayListener>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mx: 2,
              pb: 1,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Tooltip
                title={
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    Active Status
                  </Typography>
                }
                placement="bottom"
                arrow
              >
                <Switch
                  
                  color="success"
                  size="small"
                  sx={{
                    mr: 2,
                  }}
                  checked={activeState}
                  onChange={(event) => {
                    changeLinkStatus(id, event.target.checked);
                    setActiveState(event.target.checked);
                    setChangesMade(true);

                  }}
                />
              </Tooltip>
              
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                {clicks} clicks
              </Typography>
              {/* <FormGroup >
      <FormControlLabel size="small" control={} label="Status" />
   
    </FormGroup> */}
            </Box>
            <Tooltip title={<Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {socialMedia ? socialMedia : "Website"}
                  </Typography>} placement="bottom" arrow>
            <Icons socialMedia={socialMedia} />
            </Tooltip>
            {/* <Tooltip title={<Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  
                  >
                    Redirect visitors to this link
                  </Typography>} placement="bottom" arrow>
            <ToggleButton size="small" value="check"
      selected={redirectState}
      onChange={(event) => {
        setRedirectState(!redirectState);
        handleRedirectChange(event);
      }}
      sx={{
        border: "none",
        borderRadius: "50%",
      }}
      >
              <MovingIcon/>
            </ToggleButton>
            </Tooltip> */}
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                deleteLink(id);
                setChangesMade(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default LinkBox;
