import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '../components/Typography';
import { useNavigate } from 'react-router-dom';
import { logoutPost } from '../../data/auth';
import { motion } from "framer-motion";
const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function LoggedInAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = ['Profile', 'Change Password', 'Logout'];
  const user = JSON.parse(localStorage.getItem('user'));

  const settingPaths = [
    {
      path: '/manage',
      name: 'Profile',
    },
    {
      path: '/change-password',
      name: 'Change Password',
    },

  ]
  const adminSettingPaths = [
    {
      path: '/admin/create-account',
      name: 'Create Account',
    },
    {
      path: '/admin/view-users',
      name: 'View Users',
    },
    
  ]
  const navigate = useNavigate();
 
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }} >
          <Box sx={{ flex: 1 }} />
          <motion.div
            initial={{ y: -100, opacity: 0.5 }}
            animate={{ y: 0 }}
            transition={{ duration: 2 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
          <Box sx={{
            mt: 3,
            pb: 2,
          }}>
            <a
              style={{
                textDecoration: 'none',
                
              }}
              href="/"
              
            >
              <img src="/Web2.png" alt="logo" height="60" style={{
              
             
              }}/>
            </a>
          </Box>
          </motion.div>
          
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Open settings">
          <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px', mr: '20px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settingPaths.map((setting) => (
                <MenuItem key={setting.name} onClick={() => {handleCloseUserMenu(); navigate(setting.path)}} >
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
              {
                user.type === "ADMIN" && adminSettingPaths.map((setting) => (
                  <MenuItem key={setting.name} onClick={() => {handleCloseUserMenu(); navigate(setting.path)}} >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))
              }
              <MenuItem onClick={async () => {handleCloseUserMenu();
              const res = await logoutPost(localStorage.getItem('token'));
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                navigate('/sign-in');
              }} >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
          
           
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default LoggedInAppBar;
