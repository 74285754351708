import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { motion } from "framer-motion";
const backgroundImage = "/3dprinter.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <motion.div
        initial={{y: 1000, opacity: 0.3 }}
        animate={{ y: 0, opacity: 1}}
        transition={{ duration: 2 }}
      
      >
       
      <Typography color="inherit" align="center" variant="h2" marked="center" sx={{
        mt: {
          md: "auto",
          xs: "3rem"
        }
      }}>
        You Think, We Create
      </Typography>
      </motion.div>
      <motion.div
        initial={{y: 1000, opacity: 0.3 }}
        animate={{ y: 0, opacity: 1}}
        transition={{ duration: 2 }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Enjoy 3D Printed Products and Services like never before
      </Typography>
      
      <Button
        color="secondary"
        variant="contained"
        size="large"
      
        onClick={() => {
          const releventDiv = document.getElementById("get-in-touch");
          releventDiv.scrollIntoView({ behavior: "smooth" });
        }}
        sx={{ minWidth: 200 }}
      >
        Contact Us
      </Button>
      </motion.div>
      
      {/* <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Custom Orders Available
      </Typography> */}
    </ProductHeroLayout>
  );
}
