import { Navigate } from "react-router-dom";

const ProtectedAdmin = ({children}) => {
    

if (!localStorage.getItem("user") || !(JSON.parse(localStorage.getItem("user")).type === "ADMIN")) {
return <Navigate to="/sign-in" replace />;
}
return children;
};
export default ProtectedAdmin;