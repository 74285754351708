import { Container, Grid, Box, Typography, Card } from "@mui/material";
import Button from "../Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import LinkBox from "../ManageLinks/LinkBox";
import PublicAppBar from "../../views/PublicAppBar";
import withRoot from "../../withRoot";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AppFooter from "../../views/AppFooter";
import Particle from "../ViewLinks/ParticlePreview";
import PublicFooter from "../../views/PublicFooter";
import ContactInfo from "../ViewLinks/ContactInfoPreview";
// import { clickedLink, getProfileById } from "../previewData/profile";
import LoadingPage from "../../../pages/LoadingPage";
import IconsView from "./IconsView";

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirecton: "column",
};

const cardWidth = {
  xs: "100%",
  // sm: 400,
  // md: 500,
  // lg: 550,
};


function ViewLinksPreview({ previewData }) {
  const navigate = useNavigate();
  
  const [socialMedia, setSocialMedia] = React.useState([]);
  

  const socialMediaNames =  [
    "instagram",
    "facebook",
    "linkedin",
    "pinterest",
    "reddit",
    "twitter",
    "youtube",
    "twitch",
    "spotify",
    "snapchat",
    "whatsapp",
    "discord",
    "steam",
    "drive",
    "maps",
    "messenger",
    "github",
    "skype",
    "vimeo",
    "zoom",
    "dropbox",
    "freelancer",
    "fiverr",
    "upwork",
    "telegram",
    "amazon",
    "tumblr",
    "behance",
    "other"
  ];
  const identifySocialMedia = (url) => {
    let socialMedia = "other";
    socialMediaNames.forEach((name) => {
      if(url.includes(name)) {
        socialMedia = name;
      }
    }
    )
    return socialMedia;
    
  }
  useEffect(() => {
    if (previewData) {
      const socialMedia = previewData.links.map((link) => {
        return identifySocialMedia(link.url);
      });
      setSocialMedia(socialMedia);
  
    }
  }, [previewData]);

  //   useEffect(() => {
  //     setpreviewDataFn();
  //   }, []);
  return (<Box
    sx={{
      width: "100%",
      height: "100%",
      position: "fixed",
      overflow: "auto",
    }}
  >
  
    <Box
      sx={{
        overflow: "auto",
        border: {
          xs: "none",
          sm: "none",
          md: "solid black",
        },
        borderTopWidth: {
          xs: "0px",
          sm: "0px",
          md: "20px",
        },
        borderLeftWidth: {
          xs: "0px",
          sm: "0px",
          md: "10px",
        },
        borderRightWidth: {
          xs: "0px",
          sm: "0px",
          md: "10px",
        },
        borderBottomWidth: {
          xs: "0px",
          sm: "0px",
          md: "30px",
        },
        height: {
          xs: "100%",
          sm: "100%",
          md: "540px"
        },
        width: {
          // xs: "100%",
          // sm: "100%",
          md: "340px"
        },
        mx: {
          xs: "0px",
          sm: "0px",
          md: `calc(25vw - 170px)`
        },
        my: {
          xs: "0px",
          sm: "0px",
          md: `calc(45vh - 270px)`
        },

        borderRadius: {
          xs: "0px",
          sm: "0px",
          md: "30px",
        },
        
      }}
    >
      {Object.keys(previewData).length > 0 ? (
       <div
       style={{
         backgroundImage: `url(${
           previewData && previewData.profilePicture &&
           previewData.profilePicture.publicurl
         })`,
         backgroundSize: "cover",
         backgroundRepeat: "no-repeat",
         backgroundPosition: "center",
         backgroundAttachment: "fixed",
         margin: 0,
         padding: 0,
       }}
     >
       <div
         style={{
           height: "100%",
           width: "100%",
           background: "rgba(0, 0, 0, 0.5)",
           backdropFilter: "blur(30px)",
           WebkitBackdropFilter: "blur(30px)",
           margin: 0
         }}
       >
          {/* <Particle /> */}

          {/* <PublicAppBar/> */}
          <React.Fragment>
            <Box
              sx={{
                ...centerStyle,

              }}
            >
              <Card
                sx={{
                  width: {
                    xs: "100%",
                    // sm: "80%",
                    // md: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  },
                  px: 5,
                  background: "none"
                }}
                elevation={0}
              >
                <Grid
                  container
                  width="100%"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    pt: 8,
                    pb: 8,
                    width: "100%",
                  }}
                >
                  <Grid item xs={12}>
                    <Box sx={{ ...centerStyle, flexDirection: "column" }}>
                      <img
                        src={
                          previewData.profilePicture &&
                          previewData.profilePicture.publicurl
                        }
                        alt="profile picture"
                        width="200px"
                        height="200px"
                        style={{
                          borderRadius: "50%",

                          marginBottom: "20px",
                        }}
                      ></img>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      // width: cardWidth,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        textAlign: "center",
                        textTransform: "none",
                        marginBottom: "20px",
                        color: "white",
                      }}
                    >
                      {previewData.displayName}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: cardWidth,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        textTransform: "none",
                        marginBottom: "20px",
                        color: "#f4f4f4",
                      }}
                      fontWeight="400"
                    >
                      {previewData.bio}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: cardWidth,
                      ...centerStyle,
                    }}
                  >
                    <Grid container>
                      {previewData.contact.active && (
                        <Grid
                          item
                          xs={12}
                          sx={{ marginBottom: "30px", ...centerStyle }}
                        >
                          {previewData.contact.active && (
                            <ContactInfo contactInfo={previewData.contact} />
                          )}
                        </Grid>
                      )}
                      {previewData.links.map(
                        (link, index) =>
                          link.active && (
                            <Grid
                              item
                              xs={12}
                              sx={{ marginBottom: "30px", ...centerStyle }}
                              key={link.id}
                          
                            >
                              {/* <a
                        href={link.url}

                        style={{
                          textDecoration: "none",
                          width: "100%",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      > */}
                              <Button
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  px: "15px",
                                  fontSize: {
                                    xs: "1.5rem",
                                    sm: "1.5rem",
                                    md: "1.2rem",

                                  },
                                
                                  
                                }}
                                variant="contained"
                                color="primary"
                             
                                 
                                
                              > 
                                
                                <IconsView
                                    socialMedia={identifySocialMedia(link.url)}
                                    style={{
                                      marginRight: "auto",
                                    
                                    }}
                                  /> 
                          
                                
                                  <span style={{
                                    marginRight: "auto",
                                  }}>
                                  {link.title}
                                  </span>
                              </Button>
                              {/* </a> */}
                            </Grid>
                          )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Box>

            <div
              style={{
                marginTop: "5%",
              }}
            >
              <PublicFooter />
            </div>
          </React.Fragment>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </Box>
    </Box>
  );
}

export default withRoot(ViewLinksPreview);
